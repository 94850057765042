// quick shim for the storage object needed
// since localStorage doesn't work in web workers

var global = this;

(function () {
  var WorkerStorage = (function () {
    var WorkerStorage = function () {
      this.store = {}
    }

    WorkerStorage.prototype.setItem = function (key, val) {
      this.store[key] = val
    }

    WorkerStorage.prototype.getItem = function (key) {
      return this.store[key]
    }

    WorkerStorage.prototype.removeItem = function (key) {
      this.store[key] = undefined
    }

    return WorkerStorage
  })()

  if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') module.exports = WorkerStorage
  else global.WorkerStorage = WorkerStorage
})()
