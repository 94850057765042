/* globals uploader debug utils events WorkerStorage fetch */

var global = this;

(function () {
  'use strict'

  var dbg = debug('zc:cloudStore')

  var MIN_CHUNK_SIZE = 1024 * 256 // 256kb min size for cloud uploads
  var MAX_CHUNK_SIZE = 1024 * 1024 * 100 // 100mb

  var CloudStore = (function () {
    var CloudStore = function (config) {
      dbg('Initialized with config: ', {format: config.format, trackId: config.trackId})

      this.name = config.name || this.constructor.name
      this.format = config.format
      this.storageId = this.format + '::' + config.trackId
      this.deferUpload = config.deferUpload

      // indexes of chunks, not the chunks themselves
      this.chunksReceived = []
      this.chunksUploaded = []
      this.uploadErrors = []

      this.bytesReceived = 0
      this.bytesUploaded = 0
      this.uploadUrl = config.uploadUrl
      this.downloadUrl = null
      this.chunkSize = config.chunkSize || MIN_CHUNK_SIZE // 256kb

      this.socket = config.socket

      this.memoryStore = config.memoryStore
      this.persistentStore = config.persistentStore

      this.origin = global.location.origin
    }

    CloudStore.prototype.getMimeType = function () {
      var format = this.format
      if (format === 'wav') {
        return 'audio/wav'
      } else if (format === 'mp3') {
        return 'audio/mpeg'
      } else {
        throw new Error('Unable to retrieve mime type.  Missing format information.')
      }
    }

    // CloudStore.prototype.createUploadUrl = function (projectId, path) {
    //   var self = this
    //   return new Promise(function (resolve, reject) {
    //     var mimeType = self.getMimeType()
    //     self.socket.emit('uploadUrl:create', {
    //       projectId: projectId,
    //       action: action,
    //       origin: utils.getWindowOrigin()
    //     }, function (err, uploadUrl) {
    //       if (err) return reject(new Error(err))
    //       self.uploadUrl = uploadUrl
    //       resolve(uploadUrl)
    //     })
    //   }).catch(utils.logRethrow)
    // }

    CloudStore.prototype.createUploadUrl = function (projectId, path) {
      var self = this
      dbg('Creating upload url.')
      var mimeType = this.getMimeType()
      var action = 'write'
      return fetch(this.origin + '/api/cloud-storage/upload-url', {
        method: 'post',
        referrerPolicy: 'origin',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json',
          'Origin': utils.getWindowOrigin(),
          'Referer': self.origin
        },
        body: JSON.stringify({
          mimeType: mimeType,
          projectId: projectId,
          path: path,
          action: action
        })
      }).then(function (res) {
        if (res.ok) return res.text()
        throw new Error('Create upload url request failed: ' + res.status)
      }).then(function (url) {
        self.uploadUrl = url
        return url
      }).catch(utils.logRethrow)
    }

    CloudStore.prototype.createDownloadUrl = function (projectId, path) {
      var self = this
      dbg('Creating download url.')
      return fetch(this.origin + '/api/cloud-storage/download-url', {
        method: 'post',
        referrerPolicy: 'origin',
        credentials: 'same-origin',
        headers: {
          'Content-Type': 'application/json',
          'Origin': utils.getWindowOrigin(),
          'Referer': self.origin
        },
        body: JSON.stringify({
          projectId: projectId,
          path: path
        })
      }).then(function (res) {
        if (res.ok) return res.text()
        throw new Error('Create download url request failed: ' + res.status)
      }).then(function (url) {
        self.downloadUrl = url
        return url
      }).catch(utils.logRethrow)
    }

    CloudStore.prototype.getChunkSizeFromBlob = function (blob) {
      var minChunkSize = MIN_CHUNK_SIZE
      var maxChunkSize = MAX_CHUNK_SIZE

      if (blob.size < minChunkSize) return minChunkSize

      var largestPossibleChunk = Math.round(blob.size / minChunkSize) * minChunkSize

      if (largestPossibleChunk > maxChunkSize) return maxChunkSize

      return largestPossibleChunk
    }

    CloudStore.prototype.getResumableUploadProgress = function () {
      var uploadUrl = this.uploadUrl
      var byteOffset = 0
      var requestHeaders = new Headers({
        'Content-Length': 0,
        'Content-Range': 'bytes */*'
      })

      if (!uploadUrl) throw new Error('Cannot get resumable upload progress without a resumable upload url')

      return fetch(uploadUrl, {
        method: 'PUT',
        headers: requestHeaders
      }).then(function (res) {
        if (res.ok) { // this means the file is already uploaded and the response will contain the file metadata
          return res.json().then(function (uploadMeta) {
            return {byteOffset: uploadMeta.size - 1, metadata: uploadMeta}
          })
        } else if (res.status === 308) {
          // this means the file is in progress or unfinalized and the response will contain the range header
          var range = res.headers.get('range')
          if (range) {
            byteOffset = parseInt(range.split('-')[1])
          }
          return {byteOffset: byteOffset}
        }
        throw new Error('Resumable upload progress request failed: ' + res.status)
      }).catch(utils.logRethrow)
    }

    CloudStore.prototype.getUploadErrors = function () {
      return this.uploadErrors
    }

    CloudStore.prototype.getAudioBlob = function () {
      try {
        if (!this.memoryStore) {
          return this.persistentStore.exportAudioBlob()
        }

        // memoryStore is a non-persistent memory store should only be present if this is a newly recorded track in current session
        // self.memoryStore will only exist during and after a recording but before the page has been reloaded
        return Promise.resolve(this.memoryStore.exportAudioBlob())
      } catch (err) {
        dbg('Exporting audio blob from persistentStore')
        console.log('Error exporting audio blob from memory store: ', err)
        // otherwise we need to rely on the audio store which is persistent
        console.log('No audio data stored in memory.  Falling back to persistent audio store')
        return this.persistentStore.exportAudioBlob()
      }
    }

    CloudStore.prototype.uploadFromStore = function (url) {
      var self = this
      url = url || this.uploadUrl

      // this will halt any chunk uploads that may be in progress
      this.deferUpload = true

      return self.getAudioBlob().then(function (audioBlob) {
        dbg('Started uploading track from store')
        return self.upload(url, audioBlob)
      })
    }

    CloudStore.prototype.upload = function (url, blob) {
      var self = this
      return new Promise(function (resolve, reject) {
        self.uploadInProgress = true

        var chunkSize = self.getChunkSizeFromBlob(blob)
        var storage = new WorkerStorage()

        var upload = new uploader.Upload({
          id: self.storageId,
          contentType: self.getMimeType(),
          url: url,
          file: blob,
          chunkSize: chunkSize,
          storage: storage,
          onProgress: function (progress) {
            dbg('onProgress: ', progress)
            if (this.bytesUploaded !== progress.uploadedBytes) {
              self.trigger('change:bytesUploaded', this.bytesUploaded = progress.uploadedBytes)
            }
          },
          onChunkUpload: function (data) {
            dbg('onChunkUpload: ', data)
            self.trigger('chunkUploaded', data)
          }
        })

        // setTimeout(function () {
        //   reject(new Error('Upload Failed'))
        // }, 5000)

        upload.start().then(function (arg) {
          dbg('Upload Successful: ', arg)
          resolve(arg)
        }).catch(function (err) {
          console.error('Upload Failed - ', err)
          dbg('Upload failed', err)
          reject(err)
        }).then(function () {
          dbg('Upload no longer in progress')
          self.uploadInProgress = false
        })
      })
    }

    events.mixin(CloudStore.prototype)

    return CloudStore
  })()

  if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') module.exports = CloudStore
  else global.CloudStore = CloudStore
})()
