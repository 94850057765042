/* eslint-env serviceworker */
/* globals PersistentStore debug streamify */

/**
 * Stream node version of PersistentStore
 * stores incoming audio chunks consecutively into indexeddb
 */

var global = this;

(function () {
  'use strict'

  var dbg = debug('zc:persistentStoreStreamNode')

  var PersistentStoreStreamNode = (function () {
    var PersistentStoreStreamNode = function (config) {
      PersistentStore.call(this, config)
      dbg.enabled = config.debug
      dbg('Initialized with config: ', config)

      this.messagePort = config.messagePort
      if (this.messagePort) this.initPortListeners()

      // listen to the objects own events
      // so we can emit them to the main thread
      this.initSelfEventListeners()

      this.chunksIn = 0
      this.chunksOut = 0

      this.on('incomingChunk', this.processChunk)
    }

    // must be first after constructor
    PersistentStoreStreamNode.prototype = Object.create(PersistentStore.prototype)

    PersistentStoreStreamNode.prototype.initPortListeners = function () {
      var self = this
      var port = this.messagePort
      port.addEventListener('message', function (e) {
        switch (e.data.command) {
          case 'getDbMeta':
            self.getDbMeta().then(function (dbMeta) {
              port.postMessage({id: e.data.id, result: dbMeta})
            }).catch(function (err) {
              port.postMessage({id: e.data.id, err: err.toString()})
            })
            break
          case 'add':
            self.add(e.data.item).then(function () {
              port.postMessage({id: e.data.id})
            }).catch(function (err) {
              port.postMessage({id: e.data.id, err: err.toString()})
            })
            break
          case 'addAudio':
            self.addAudio(e.data.audioChunk).then(function () {
              port.postMessage({id: e.data.id})
            }).catch(function (err) {
              port.postMessage({id: e.data.id, err: err.toString()})
            })
            break
          // case 'slice':
          //   self.slice(e.data.start, e.data.end).then(function (chunks) {
          //     port.postMessage({id: e.data.id, result: chunks})
          //   }).catch(function (err) {
          //     port.postMessage({id: e.data.id, err: err.toString()})
          //   })
          //   break
          // case 'mergedSlice':
          //   self.addAudio(e.data.start, e.data.end).then(function (item) {
          //     port.postMessage({id: e.data.id, result: item})
          //   }).catch(function (err) {
          //     port.postMessage({id: e.data.id, err: err.toString()})
          //   })
          //   break
          case 'export':
            self.export().then(function (items) {
              port.postMessage({id: e.data.id, result: items})
            }).catch(function (err) {
              port.postMessage({id: e.data.id, err: err.toString()})
            })
            break
          case 'mergedExport':
            self.mergedExport().then(function (item) {
              port.postMessage({id: e.data.id, result: item})
            }).catch(function (err) {
              port.postMessage({id: e.data.id, err: err.toString()})
            })
            break
          case 'clear':
            self.clear().then(function () {
              port.postMessage({id: e.data.id})
            }).catch(function (err) {
              port.postMessage({id: e.data.id, err: err.toString()})
            })
            break
          case 'dbClose':
            self.dbClose().then(function () {
              port.postMessage({id: e.data.id})
            }).catch(function (err) {
              port.postMessage({id: e.data.id, err: err.toString()})
            })
            break
        }
      }, false)

      port.start()
    }

    PersistentStoreStreamNode.prototype.initSelfEventListeners = function (e) {
      var self = this
      var port = this.messagePort

      // listen to own events so we can proxy them back through the message channel
      this.on('change:bytesSaved', function (bytesSaved) {
        port.postMessage({command: 'change:bytesSaved', bytesSaved: bytesSaved, chunksIn: self.chunksIn, chunksOut: self.chunksOut})
      })
    }

    PersistentStoreStreamNode.prototype.processChunk = function (chunk, isLast) {
      var self = this

      if (chunk) {
        dbg('processingChunk')
        this.chunksIn++
        dbg(this.format, ' chunk in - ', this.chunksIn, '/', this.chunksOut)
        this.addAudio(chunk)
          .catch(self.reportSaveError.bind(self))
          .then(function () {
            self.chunksOut++
            dbg(self.format, ' chunk out - ', self.chunksIn, '/', self.chunksOut)
          })
      }

      // temporarily move this outside of the addAudio promise
      // slow idb saves have been backing up the entire pipeline
      self.trigger('outgoingChunk', chunk, isLast)
    }

    PersistentStoreStreamNode.prototype.reportSaveError = function (err) {
      this.messagePort.postMessage({command: 'saveError', err: err.toString()})
    }

    streamify.mixin(PersistentStoreStreamNode.prototype)

    return PersistentStoreStreamNode
  })()

  if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') module.exports = PersistentStoreStreamNode
  else global.PersistentStoreStreamNode = PersistentStoreStreamNode
})()
