/* globals debug events utils */

var global = this;

(function () {
  'use strict'

  var dbg = debug('zc:memoryStore')

  var MemoryStore = (function () {
    var MemoryStore = function (config) {
      dbg('Initialized with config: ', config)

      if (typeof config.ChunkConstructor === 'string') {
        config.ChunkConstructor = global[config.ChunkConstructor] // make constructor real if a string is passed
      }

      if (!(config.ChunkConstructor instanceof Function) || !(new config.ChunkConstructor().buffer instanceof ArrayBuffer)) {
        throw new Error('You must initialize with a typed array constructor')
      }

      if (['wav', 'mp3'].indexOf(config.format) < 0) {
        throw new Error('You must initilalize with a valid audio format')
      }

      this.name = config.name || this.constructor.name
      this.format = config.format
      this.ChunkConstructor = config.ChunkConstructor
      this.chunkSize = config.chunkSize
      this.chunks = []
      this.totalLength = 0
      this.totalBytes = 0
    }

    MemoryStore.prototype._merge = function (chunks, totalLength) {
      var merged = new this.ChunkConstructor(totalLength)
      var offset = 0
      for (var i = 0; i < chunks.length; i++) {
        merged.set(new this.ChunkConstructor(chunks[i]), offset)
        offset += chunks[i].length
      }
      return merged
    }

    MemoryStore.prototype.addChunk = function (chunk) {
      this.chunks.push(chunk)
      this.totalLength += chunk.length
      this.trigger('change:bytesRecorded', this.totalBytes += chunk.byteLength)
      dbg(this.format, ':addChunk - chunkBytes: ', chunk.byteLength, ' totalBytes: ', this.totalBytes)
    }

    MemoryStore.prototype.getChunk = function (index) {
      return this.chunks[index]
    }

    MemoryStore.prototype.slice = function (start, end) {
      return this.chunks.slice(start, end)
    }

    MemoryStore.prototype.mergedSlice = function (start, end) {
      var slice = this.chunks.slice(start, end)
      var length = 0
      for (var i = 0; i < slice.length; i++) {
        length += slice[i].length
      }
      var merged = this._merge(slice, length)
      return merged
    }

    MemoryStore.prototype.export = function () {
      return this.chunks
    }

    MemoryStore.prototype.mergedExport = function () {
      return this._merge(this.chunks, this.totalLength)
    }

    MemoryStore.prototype.exportAudioBlob = function () {
      if (!this.chunks.length) throw new Error('Store is empty')
      return utils.audio.convertAudioChunksToFormattedBlob(this.format, this.chunks)
    }

    MemoryStore.prototype.clear = function (unmerged) {
      this.chunks = []
      this.totalLength = 0
      this.totalBytes = 0
    }

    events.mixin(MemoryStore.prototype)

    return MemoryStore
  })()

  if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') module.exports = MemoryStore
  else global.MemoryStore = MemoryStore
})()
