/* globals utils uploader streamify CloudStore MemoryStoreStreamNode PersistentStoreStreamNode WorkerStorage debug */

var global = this;

(function () {
  'use strict'

  var dbg = debug('zc:cloudStoreStreamNode')

  var CloudStoreStreamNode = (function () {
    var CloudStoreStreamNode = function (config) {
      CloudStore.call(this, config)
      dbg.enabled = config.debug
      dbg('Initialized with config: ', config)

      if (config.messagePort && !(config.messagePort instanceof MessagePort)) {
        throw new Error('config.messagePort must an instance of MessagePort')
      }

      if (config.memoryStore && !(config.memoryStore instanceof MemoryStoreStreamNode)) {
        throw new Error('config.memoryStore must be an instance of MemoryStore')
      }

      if (config.persistentStore && !(config.persistentStore instanceof PersistentStoreStreamNode)) {
        throw new Error('config.persistentStore must be an instance of PersistentStore')
      }

      this.messagePort = config.messagePort
      if (this.messagePort) this.initPortListeners()

      // listen to the objects own upload events
      this.initUploadEventsListeners()

      // We need a reference to the memory store
      // we'll have to search the pipeline for it
      if (config.pipeline && !config.memoryStore) {
        this.memoryStore = this.findMemoryStore(config.pipeline)
      }

      // We need a reference to the persistent store
      // we'll have to search the pipeline for it
      if (config.pipeline && !config.persistentStore) {
        this.persistentStore = this.findPersistentStore(config.pipeline)
      }

      this.on('incomingChunk', this.processChunk)

      // if (this.uploadUrl) this.initStreamingUpload()
    }

    // must be first after constructor
    CloudStoreStreamNode.prototype = Object.create(CloudStore.prototype)

    CloudStoreStreamNode.prototype.findMemoryStore = function (pipeline) {
      var memoryStore
      pipeline.forEach(function (node) { if (node instanceof MemoryStoreStreamNode) memoryStore = node })
      if (!memoryStore) throw new Error('Failed to find memory store in pipeline')
      return memoryStore
    }

    CloudStoreStreamNode.prototype.findPersistentStore = function (pipeline) {
      var persistentStore
      pipeline.forEach(function (node) { if (node instanceof PersistentStoreStreamNode) persistentStore = node })
      if (!persistentStore) throw new Error('Failed to find persistent store in pipeline')
      return persistentStore
    }

    CloudStoreStreamNode.prototype.initPortListeners = function (e) {
      var self = this
      var port = this.messagePort
      port.onmessage = function (e) {
        switch (e.data.command) {
          case 'createUploadUrl':
            self.createUploadUrl(e.data.projectId, e.data.path).then(function (url) {
              port.postMessage({id: e.data.id, result: url})
            }).catch(function (err) {
              port.postMessage({id: e.data.id, err: err.toString()})
            })
            break
          case 'initStreamingUpload':
            try {
              self.initStreamingUpload(e.data.url)
              // try and get the range of of uploaded chunks
              // this is mainly to check if the uploadUrl is valid
              self.uploader.getRemoteResumeIndex()
              .then(function () {
                port.postMessage({id: e.data.id})
              }).catch(function (err) {
                port.postMessage({id: e.data.id, err: err.toString()})
              })
            } catch (err) {
              port.postMessage({id: e.data.id, err: err.toString()})
            }
            break
          case 'upload':
            self.upload(e.data.url, e.data.blob).then(function () {
              port.postMessage({id: e.data.id})
            }).catch(function (err) {
              port.postMessage({id: e.data.id, err: err.toString()})
            })
            break
          case 'uploadFromStore':
            self.uploadFromStore().then(function (fileMeta) {
              port.postMessage({id: e.data.id, fileMeta: fileMeta})
            }).catch(function (err) {
              port.postMessage({id: e.data.id, err: err.toString()})
            })
            break
          case 'createDownloadUrl':
            self.createDownloadUrl(e.data.projectId, e.data.path).then(function (url) {
              port.postMessage({id: e.data.id, result: url})
            }).catch(function (err) {
              port.postMessage({id: e.data.id, err: err.toString()})
            })
            break
          case 'getResumableUploadProgress':
            self.getResumableUploadProgress().then(function (progress) {
              port.postMessage({id: e.data.id, result: progress})
            }).catch(function (err) {
              port.postMessage({id: e.data.id, err: err.toString()})
            })
            break
          case 'getUploadErrors':
            try {
              var uploadErrors = self.getUploadErrors()
              port.postMessage({id: e.data.id, result: uploadErrors})
            } catch (err) {
              port.postMessage({id: e.data.id, err: err.toString()})
            }
            break
        }
      }

      port.postMessage({command: 'ready'})
    }

    CloudStoreStreamNode.prototype.initUploadEventsListeners = function (e) {
      var port = this.messagePort

      // listen to own events so we can proxy them back through the message channel
      this.on('chunkUploaded', function (chunkMeta) {
        port.postMessage({command: 'chunkUploaded', chunkMeta: chunkMeta})
      })
      this.on('change:bytesUploaded', function (bytesUploaded) {
        port.postMessage({command: 'change:bytesUploaded', bytesUploaded: bytesUploaded})
      })
      this.on('chunkUploadFailed', function (chunkMeta) {
        port.postMessage({command: 'chunkUploadFailed', chunkMeta: chunkMeta})
      })
    }

    CloudStoreStreamNode.prototype.getValidUploadChunk = function (chunk, isLast) {
      // GCS upload chunks need to be a multiple of 256k except for the final chunk
      this.chunkQueueBytes = this.chunkQueueBytes || 0
      this.chunkQueueLength = this.chunkQueueLength || 0
      this.chunkQueue = this.chunkQueue || []

      this.chunkQueueBytes += chunk.byteLength
      this.chunkQueueLength += chunk.length
      this.chunkQueue.push(chunk)

      var isValidSize = this.chunkQueueBytes % this.chunkSize === 0
      if (isValidSize || isLast) {
        var validChunk = utils.audio.mergeTypedArrays(this.chunkQueue, this.chunkQueueLength)
        this.chunkQueueBytes = 0
        this.chunkQueueLength = 0
        this.chunkQueue = []
        return validChunk
      }
    }

    CloudStoreStreamNode.prototype.processChunk = function (chunk, isLast) {
      var self = this
      var chunkIndex = this.chunksReceived.length
      chunk = chunk || new Float32Array()

      if (!this.deferUpload) {
        var validUploadChunk = this.getValidUploadChunk(chunk, isLast)
        if (validUploadChunk) {
          // if (chunkIndex === 1) validUploadChunk = new Blob([new Uint8Array(32768)])
          this.uploadChunk(chunkIndex, validUploadChunk, isLast).catch(function (err) {
            self.uploadErrors.push(err.toString())
            console.error(err)
          })
        }
      }

      this.trigger('outgoingChunk', chunk, isLast)
    }

    CloudStoreStreamNode.prototype.getNextChunkFromRecorder = function (index, isLast) {
      // the memory store won't normally have the same chunk size as our upload chunks
      var memoryChunksPerUploadChunk = this.chunkSize / this.memoryStore.chunkSize
      var memoryChunkOffset = index * memoryChunksPerUploadChunk
      if (isLast) return this.memoryStore.mergedSlice(memoryChunkOffset)
      else return this.memoryStore.mergedSlice(memoryChunkOffset, memoryChunkOffset + memoryChunksPerUploadChunk)
    }

    CloudStoreStreamNode.prototype.isBehind = function (isLast) {
      return isLast ? this.chunksUploaded.length < this.chunksReceived.length - 1 : this.chunksUploaded.length !== this.chunksReceived.length
    }

    CloudStoreStreamNode.prototype.uploadChunk = function (chunkIndex, chunk, isLast) {
      var self = this
      if (this.deferUpload) return
      var behind = self.isBehind(isLast)
      dbg('Uploader Behind: %s, chunksUploaded: %s, chunksReceived: %s', behind, self.chunksUploaded.length, self.chunksReceived.toString())
      this.chunksReceived.push(chunkIndex)
      return new Promise(function (resolve, reject) {
        if (!self.uploadUrl) {
          console.warn('Haven\'t received uploadUrl response yet.  Postponing upload.')
          return resolve()
        }

        if (self.uploadInProgress) {
          console.warn('Upload still in progress.  Waiting to upload next chunk.')
          if (isLast) {
            console.log('LAST CHUNK WAITING - index: ', chunkIndex, ' chunk.byteLength: ', chunk.byteLength, ' isLast: ', isLast)
            console.log('UPLOAD IN PROGRESS: ', self.uploadInProgress)
            self.once('chunkUploaded chunkUploadFailed', function () {
              // trigger last chunk to upload once the current upload completes
              console.log('Uploading final chunk - index: ', chunkIndex, ' chunk.byteLength: ', chunk.byteLength, ' isLast: ', isLast)
              console.log('UPLOAD IN PROGRESS: ', self.uploadInProgress)
              self.uploadChunk(chunkIndex, chunk, isLast)
            })
            return resolve()
          } else {
            // do nothing, we'll be behind but catch up at the end
            return resolve()
          }
        } else if (behind) {
          // we are behind and need to retry chunks
          chunkIndex = self.chunksUploaded.length
          console.warn('Uploads are behind.  Grabbing next chunk from memoryStore: ', chunkIndex)
          chunk = self.getNextChunkFromRecorder(chunkIndex, isLast)
        }

        self.uploadInProgress = true
        self.uploadChunkStartTime = performance.now()

        var isValidChunkSize = chunk.byteLength % self.chunkSize === 0
        if (!isLast && !isValidChunkSize) { throw new Error('Cannot upload chunk of invalid chunk size:' + chunk.byteLength + ' index: ' + chunkIndex) }

        // intentionally invalid chunk size for testing
        // if (chunkIndex === 1) chunk = new Blob([new Uint8Array(32768)])

        // run this inside setTimeout to break up the processing
        setTimeout(function () {
          self.uploader.uploadChunk(chunkIndex, chunk, isLast).then(resolve, reject).then(function () {
            resolve()
            self.uploadChunkEndTime = performance.now()
            dbg(uploadTimeLogId, self.uploadChunkEndTime - self.uploadChunkStartTime)
          }).catch(reject)
        }, 0)

        if (dbg.enabled) {
          var format = self.format
          var uploadTimeLogId = format + ':uploadChunk:' + chunk.byteLength
          var sinceLastLogId = format + ':sinceLastUpload'
          if (self.uploadChunkEndTime) dbg(sinceLastLogId, self.uploadChunkStartTime - self.uploadChunkEndTime)
        }
      })
    }

    CloudStoreStreamNode.prototype.initStreamingUpload = function (url) {
      var self = this
      var chunkSize = self.chunkSize || self.minChunkSize

      var storage = new WorkerStorage()

      self.uploader = new uploader.UploadStream({
        id: self.storageId,
        contentType: self.getMimeType(),
        url: url || self.uploadUrl,
        chunkSize: chunkSize,
        backoffRetryMillis: 1000,
        backoffRetryAttempts: 5, // exponential backoff totalling 5 retries over 31 seconds before giving up
        storage: storage,
        onProgress: function (progress) {
          dbg('CLOUD STORAGE PROGRESS: ', progress)
          if (self.bytesUploaded !== progress.uploadedBytes) {
            self.trigger('change:bytesUploaded', self.bytesUploaded = progress.uploadedBytes)
          }
        },
        onChunkUpload: function (data) {
          dbg('CLOUD STORAGE CHUNK UPLOADED: ', data)
          self.chunksUploaded.push(data.chunkIndex)
          self.uploadInProgress = false
          self.trigger('chunkUploaded', data)
        },
        onChunkUploadFail: function (data) {
          self.uploadInProgress = false
          self.trigger('chunkUploadFailed', data)
        }
      })

      return self.uploader
    }

    streamify.mixin(CloudStoreStreamNode.prototype)

    return CloudStoreStreamNode
  })()

  if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') module.exports = CloudStoreStreamNode
  else global.CloudStoreStreamNode = CloudStoreStreamNode
})()
