/* globals streamify debug MemoryStore */

/**
 * Stream node version of MemoryStore
 * stores incoming audio chunks consecutively into an in-memory array
 */

var global = this;

(function () {
  'use strict'

  var dbg = debug('zc:memoryStoreStreamNode')

  var MemoryStoreStreamNode = (function () {
    var MemoryStoreStreamNode = function (config) {
      MemoryStore.call(this, config)
      dbg.enabled = config.debug
      dbg('Initialized with config: ', config)

      var self = this

      this.messagePort = config.messagePort
      if (this.messagePort) this.initPortListeners()

      this.on('incomingChunk', this.processChunk)

      // listen for own bytesRecorded event and broadcast it
      // through the message port
      this.on('change:bytesRecorded', function (bytesRecorded) {
        self.messagePort.postMessage({command: 'change:bytesRecorded', bytesRecorded: bytesRecorded})
        dbg(this.format + ':change:bytesRecorded - ' + bytesRecorded)
        // console.timeEnd(this.format + ':change:bytesRecorded')
        // console.time(this.format + ':change:bytesRecorded')
      })

      dbg('init complete')
      dbg('this.messagePort instanceof MessagePort ===', this.messagePort instanceof MessagePort)
    }

    // must be first after constructor
    MemoryStoreStreamNode.prototype = Object.create(MemoryStore.prototype)

    MemoryStoreStreamNode.prototype.initPortListeners = function (e) {
      var self = this
      var port = this.messagePort
      port.addEventListener('message', function (e) {
        switch (e.data.command) {
          case 'getChunk':
            try {
              port.postMessage({id: e.data.id, result: self.getChunk(e.data.projectId, e.data.path)})
            } catch (err) {
              port.postMessage({id: e.data.id, err: err.toString()})
            }
            break
          case 'slice':
            try {
              port.postMessage({id: e.data.id, result: self.slice(e.data.start, e.data.end)})
            } catch (err) {
              port.postMessage({id: e.data.id, err: err.toString()})
            }
            break
          case 'mergedSlice':
            try {
              port.postMessage({id: e.data.id, result: self.mergedSlice(e.data.start, e.data.end)})
            } catch (err) {
              port.postMessage({id: e.data.id, err: err.toString()})
            }
            break
          case 'export':
            try {
              port.postMessage({id: e.data.id, result: self.export()})
            } catch (err) {
              port.postMessage({id: e.data.id, err: err.toString()})
            }
            break
          case 'mergedExport':
            try {
              port.postMessage({id: e.data.id, result: self.mergedExport()})
            } catch (err) {
              port.postMessage({id: e.data.id, err: err.toString()})
            }
            break
        }
      }, false)

      port.start()
    }

    MemoryStoreStreamNode.prototype.processChunk = function (chunk, isLast) {
      if (chunk) {
        dbg(this.format, ':processChunk - bytes: ', chunk.byteLength, ' isLast: ', isLast)
        // clone chunk and store it
        this.addChunk(chunk.slice(0))
      }

      this.trigger('outgoingChunk', chunk, isLast)
    }

    streamify.mixin(MemoryStoreStreamNode.prototype)

    return MemoryStoreStreamNode
  })()

  if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') module.exports = MemoryStoreStreamNode
  else global.MemoryStoreStreamNode = MemoryStoreStreamNode
})()
